<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>   
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/avaliacoes-modelos')"
              >Modelos de avaliação</a>
            </li>      
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/avaliacao-modelo/' + parseInt($route.params.id_avaliacao_modelo))"
              >Unidades curriculares</a>
            </li>         
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Unidade curricular</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">          
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Configurar
                  <span>avaliação</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row mt-4">
                <div class="col-12">
                  <h4>Unidade curricular - {{fastModeloUcs.uc.nome_curso}}</h4>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <div class="card shadow mb-4">
                        <div class="card-body">
                          <div v-if="fastModeloUcs.loading" class="col-12">
                            <b-icon
                                icon="gear-fill"
                                animation="spin"
                              /> Carregando dados
                          </div>
                          <div v-else class="col-12">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                <label class="font-weight-bold">Próximo módulo</label>
                                <button class="btn btn-sm btn-secondary ml-2 pt-0 pb-0">
                                  <small> <b-icon-pencil-square />  Alterar</small>
                                </button>
                                <input                
                                  type="text"
                                  class="form-control"
                                  readonly
                                  :value="fastModeloUcs.uc.id_secretaria_curso_proximo_programa ? fastModeloUcs.uc.id_secretaria_curso_proximo_programa : 'Nenhum definido'"
                                >
                              </div>                             
                            </div>
                            <div class="row mt-4">
                              <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                <label class="font-weight-bold">Próximo unidade curricular</label>
                                <button class="btn btn-sm btn-secondary ml-2 pt-0 pb-0">
                                  <small> <b-icon-pencil-square />  Alterar</small>
                                </button>
                                <input                
                                  type="text"
                                  class="form-control"
                                  readonly
                                  :value="fastModeloUcs.uc.id_secretaria_curso_proximo_uc ? fastModeloUcs.uc.id_secretaria_curso_proximo_uc : 'Nenhum definido'"
                                >
                              </div>            
                            </div>
                            
                          </div>
                        </div>
                      </div>
                    </div>                        
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <h4>Avaliação</h4>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <div class="card shadow mb-4">
                        <div class="card-body">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="fd-app-curso-aulas">
                                      <div class="fd-app-curso-aulas-list mt-0">
                                        <div
                                          class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0"
                                        >
                                          <div
                                            class="col-sm-12 col-md-12 col-lg-12 mb-3"
                                          >
                                            <div class="col-12 text-center mb-1">
                                              <span>Habilitar avaliação</span>
                                            </div>
                                            <div class="col-12 text-center">
                                              <label class="switch">
                                                <input
                                                  v-model="
                                                    fastModeloUcs.uc.avaliacao_configurada
                                                  "
                                                  @change="editaAvaliacaoUc()"
                                                  type="checkbox"
                                                >
                                                <span class="slider round" />
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> 
                                  <div v-if="fastModeloUcs.uc.avaliacao_configurada" class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="row">
                                      <div class="col-12">
                                        <label class="font-weight-bold">Configuração de avaliação</label>
                                      </div>                            
                                    </div>
                                    <div class="row">
                                      <div class="col-12">
                                        <select class="form-control" @change="alteraPeriodoAvaliacao" ref="selPeriodoAvaliacao">
                                          <option value="">-- Selecione a data da avaliação --</option>
                                          <option value="F">Ao final do módulo</option>
                                          <option value="D">Dias após início UC</option>
                                        </select>
                                      </div>
                                      <div v-if="!fastAvaliacaoConfiguracao.final_modulo" class="col-12 mt-2">
                                        <div class="form-check form-check-inline">
                                          <input v-model="fastAvaliacaoConfiguracao.liberar_prazo" type="number" class="form-control mr-2" @blur="editaAvaliacaoConfiguracao()"> dias
                                        </div>                                      
                                      </div>
                                    </div>  
                                    <div class="row mt-4">
                                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                        <label class="font-weight-bold">Avaliação por nota</label>
                                          <div class="col-12 table-responsive mt-2">
                                            <table class="table table-sm">
                                              <tr>
                                                <td>Nota máxima</td>
                                                <td>
                                                  <input type="text" class="form-control" v-model="fastAvaliacaoConfiguracao.nota_maxima" @blur="editaAvaliacaoConfiguracao()">
                                                </td>
                                                <td></td>
                                              </tr>
                                              <tr>
                                                <td>Passar direto</td>
                                                <td>
                                                  <input type="text" class="form-control" v-model="fastAvaliacaoConfiguracao.passar_direto" @blur="editaAvaliacaoConfiguracao()">
                                                </td>
                                                <td>Tipo de resultado</td>
                                              </tr>
                                              <tr>
                                                <td>Média aprovação</td>
                                                <td>
                                                  <input type="text" class="form-control" v-model="fastAvaliacaoConfiguracao.media_aprovacao" @blur="editaAvaliacaoConfiguracao()">
                                                </td>
                                                <td>Tipo de resultado</td>
                                              </tr>
                                            </table>
                                          </div>
                                      </div>
                                      
                                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                        <label class="font-weight-bold">Arredondamento de notas</label>
                                        <button
                                          class="btn btn-sm btn-primary ml-2"
                                          @click.prevent="showModal('modalCriarAvaliacaoArredondamento')"
                                        >
                                          <small>+ Adicionar</small>
                                        </button>
                                          <div class="col-12 table-responsive mt-2">
                                            <table class="table table-sm">
                                              <tr>
                                                <th>Faixa inicial</th>
                                                <th>Faixa final</th>
                                                <th>Faixa nota</th>
                                                <th>Ações</th>
                                              </tr>
                                              <tr v-for="(e, index) in fastAvaliacaoConfiguracaoArredondamentos" :key="index">
                                                <td>{{adicionaCasaDecimal(e.faixa_inicial)}}</td>
                                                <td>{{adicionaCasaDecimal(e.faixa_final)}}</td>
                                                <td>{{adicionaCasaDecimal(e.nota)}}</td>
                                                <td>
                                                  <button
                                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                                    @click="exibeModalEditarAvaliacaoArredondamento('A', e)"
                                                  >
                                                    <small>Editar</small>
                                                  </button>                                                
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <label class="h6 mt-4">Título da avaliação</label>
                                          </div>
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <input
                                              v-model="fastSecretariaAvaliacao.titulo_avaliacao"
                                              type="text"
                                              class="form-control"
                                            >
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-12 mb-2">
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <label class="h6 mt-4">Detalhes da avaliação</label>
                                          </div>
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <textarea
                                              v-model="fastSecretariaAvaliacao.detalhe_avaliacao"
                                              class="form-control"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <label class="h6 mt-4">Tentativas máxima</label>
                                          </div>
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <input
                                              v-model="fastSecretariaAvaliacao.tentativas_maxima"
                                              type="number"
                                              class="form-control text-center"
                                            >
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <label class="h6 mt-4">Tempo limite</label>
                                          </div>
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <input
                                              v-model="fastSecretariaAvaliacao.tempo_limite"
                                              type="time"
                                              class="form-control text-center"
                                            >
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                            <label class="h6 mt-4">Randomizar ordem</label>
                                            <div class="fd-app-curso-aulas mt-0">
                                            <div class="fd-app-curso-aulas-list mt-0">
                                              <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                  <div class="col-12 text-center">
                                                    <label class="switch">
                                                      <input
                                                        v-model="fastSecretariaAvaliacao.randomizar_ordem"
                                                        type="checkbox"
                                                      >
                                                      <span class="slider round" />
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div> 
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <label class="h6 mt-4">Limite de questões</label>
                                          </div>
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <input
                                              v-model="fastSecretariaAvaliacao.limite_questoes"
                                              type="number"
                                              class="form-control text-center"
                                            >
                                          </div>
                                        </div>
                                      </div>

                                      <div v-if="fastModeloUcs.uc.id_secretaria_avaliacao_principal" class="col-12 text-center mt-4">
                                        <button
                                          class="btn btn-primary mt-2 ml-2"
                                          @click.prevent="editaSecretariaAvaliacao('A')"
                                        >
                                          <small>
                                            <b-icon-check2-circle /> 
                                            &nbsp;Salvar alterações
                                          </small>
                                        </button>
                                      </div>
                                      <div v-else class="col-12 text-center">
                                        <button
                                          class="btn btn-primary mt-2 ml-2"
                                          @click.prevent="insereSecretariaAvaliacao('A')"
                                        >
                                          <small>
                                            <i
                                              class="fa fa-floppy-o"
                                              aria-hidden="true"
                                            />
                                            &nbsp;Criar avaliação
                                          </small>
                                        </button>
                                      </div>

                                      <div
                                        v-if="fastModeloUcs.uc.id_secretaria_avaliacao_principal"
                                        class="col-12 mt-4"
                                      >
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                                            <h4 class="aluno_font_color">
                                              Questões da Avaliação ({{fastSecretariaAvaliacaoQuestoes.length}})
                                            </h4>
                                          </div>
                                          <div class="col-sm-12 col-md-12 col-lg-6 mt-4 text-right">
                                            <a
                                              class="btn btn-primary btn-sm mt-2 ml-2"
                                              :href="'/plataforma/' + $route.params.id_plataforma + '/admin/provas?id_secretaria_avaliacao=' + fastModeloUcs.uc.id_secretaria_avaliacao_principal + '&id_secretaria_avaliacao_uc=' + this.$route.params.id_secretaria_avaliacao_uc + '&id_avaliacao_modelo=' + this.$route.params.id_avaliacao_modelo + '#questões'"
                                            >
                                              <small>
                                                <i
                                                  class="fa fa-question-circle"
                                                  aria-hidden="true"
                                                />
                                                &nbsp;Gerenciar questões
                                              </small>
                                            </a>
                                          </div>
                                        </div>
                                        
                                        
                                        <div class="row pl-4 pr-4 pb-4 mt-2">
                                          <div class="col-12 mt-2 pl-4 pr-4">
                                            <div
                                              class="progress"
                                              style="height: 50px"
                                            >
                                              <div
                                                :class="'progress-bar progress-bar-striped progress-bar-animated bg-success'"
                                                role="progressbar"
                                                :aria-valuenow="{ width: fastSecretariaAvaliacaoPorcentagem }"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                :style="{ width: fastSecretariaAvaliacaoPorcentagem + '%' }"
                                              >
                                                <h6 class="m-0">
                                                  Avaliação 
                                                </h6>
                                                <h6 class="m-0">
                                                  Peso:
                                                  <span>{{ fastSecretariaAvaliacaoQuestoesPeso }}</span>
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                          
                                          <div
                                            v-if="fastSecretariaAvaliacaoQuestoes.length"
                                            class="col-12"
                                          >
                                            <div class="row pl-4 pr-4 pb-4">
                                              <div class="col-12">
                                                <div class="row pb-4">
                                                  <carousel
                                                    :navigation-enabled="true"
                                                    :per-page="1"
                                                    :mouse-drag="false"
                                                    :adjustable-height="true"
                                                    :pagination-size="20"
                                                    :resistance-coef="40"
                                                    class="col-12 shadow-lg p-2 mb-2 bg-white rounded"
                                                  >
                                                    <slide
                                                      v-for="(q, index) in fastSecretariaAvaliacaoQuestoes"
                                                      :key="q.id_questao"
                                                    >
                                                      <div class="row p-4">
                                                        <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
                                                          <h5>
                                                            Questão #{{q.id_questao}} - {{ index + 1 }} de
                                                            {{ fastSecretariaAvaliacaoQuestoes.length }}
                                                          </h5>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-5 mt-2">
                                                          <div class="row">
                                                            <div class="col-sm-12 col-md-12 col-lg-7 text-right">
                                                              <h5>Peso da questão</h5>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-5 pl-0">
                                                              <input
                                                                v-model="q.questao_peso"
                                                                type="number"
                                                                step=".01"
                                                                class="form-control text-center"
                                                                @change="atualizaPesoQuestaoAvaliacao(q, 'A')"
                                                              >
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          class="col-sm-12 col-md-12 col-lg-3 mt-2 text-right"
                                                        >
                                                          <button
                                                            class="btn btn-sm btn-danger"
                                                            @click="exibeModalExcluirQuestaoAvaliacao(q, 'A')"
                                                          >
                                                            <small class="pt-0 pb-0">Excluir da avaliação</small>
                                                          </button>
                                                        </div>

                                                        <div class="col-12 mt-4">
                                                          <div v-html="q.pergunta" />
                                                        </div>
                                                        <div
                                                          v-if="q.alternativas.length"
                                                          class="col-12 p-4"
                                                        >
                                                          <div
                                                            v-for="(a) in q.alternativas"
                                                            :key="a.id_alternativa"
                                                            class="row pl-2 pr-2"
                                                          >
                                                            <div :class="'col-12 alt-correta-' + a.correta">
                                                              <div class="row">
                                                                <div class="col-6 mt-2">
                                                                  <h6>Alternativa #{{ a.id_alternativa }}</h6>
                                                                </div>
                                                                <div class="col-12">
                                                                  <div v-html="a.alternativa" />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </slide>
                                                  </carousel>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>   
                                                      
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>                        
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12">
                  <h4>Recuperação</h4>
                </div>
                <div class="col-12">
                  <div class="row">
                    <div class="col-12">
                      <div class="card shadow mb-4">
                        <div class="card-body">
                          <div class="col-12">
                            <div class="row">
                              <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                                <div class="row">
                                  <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="fd-app-curso-aulas">
                                      <div class="fd-app-curso-aulas-list mt-0">
                                        <div
                                          class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0"
                                        >
                                          <div
                                            class="col-sm-12 col-md-12 col-lg-12 mb-3"
                                          >
                                            <div class="col-12 text-center mb-1">
                                              <span>Habilitar recuperação</span>
                                            </div>
                                            <div class="col-12 text-center">
                                              <label class="switch">
                                                <input
                                                  v-model="
                                                    fastModeloUcs.uc.recuperacao_configurada
                                                  "
                                                  @change="editaAvaliacaoUc()"
                                                  type="checkbox"
                                                >
                                                <span class="slider round" />
                                              </label>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div> 
                                  <div v-if="fastModeloUcs.uc.recuperacao_configurada" class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="row">
                                      <div class="col-12">
                                        <label class="font-weight-bold">Configuração de recuperação</label>
                                      </div>                            
                                    </div>
                                    <div class="row">
                                      <div class="col-12">
                                        <select class="form-control" @change="alteraPeriodoRecuperacao" ref="selPeriodoRecuperacao">
                                          <option value="">-- Selecione a data da avaliação --</option>
                                          <option value="F">Ao final do módulo</option>
                                          <option value="D">Dias após início UC</option>
                                        </select>
                                      </div>
                                      <div v-if="!fastRecuperacaoConfiguracao.final_modulo" class="col-12 mt-2">
                                        <div class="form-check form-check-inline">
                                          <input v-model="fastRecuperacaoConfiguracao.liberar_prazo" type="number" class="form-control mr-2" @blur="editaRecuperacaoConfiguracao()"> dias
                                        </div>                                      
                                      </div>
                                    </div>  
                                    <div class="row mt-4">
                                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                        <label class="font-weight-bold">Avaliação por nota</label>
                                          <div class="col-12 table-responsive mt-2">
                                            <table class="table table-sm">
                                              <tr>
                                                <td>Nota máxima</td>
                                                <td>
                                                  <input type="text" class="form-control" v-model="fastRecuperacaoConfiguracao.nota_maxima" @blur="editaRecuperacaoConfiguracao()">
                                                </td>
                                                <td></td>
                                              </tr>
                                              <tr>
                                                <td>Passar direto</td>
                                                <td>
                                                  <input type="text" class="form-control" v-model="fastRecuperacaoConfiguracao.passar_direto" @blur="editaRecuperacaoConfiguracao()">
                                                </td>
                                                <td>Tipo de resultado</td>
                                              </tr>
                                              <tr>
                                                <td>Média aprovação</td>
                                                <td>
                                                  <input type="text" class="form-control" v-model="fastRecuperacaoConfiguracao.media_aprovacao" @blur="editaRecuperacaoConfiguracao()">
                                                </td>
                                                <td>Tipo de resultado</td>
                                              </tr>
                                            </table>
                                          </div>
                                      </div>
                                      
                                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                        <label class="font-weight-bold">Arredondamento de notas</label>
                                        <button
                                          class="btn btn-sm btn-primary ml-2"
                                          @click.prevent="showModal('modalCriarRecuperacaoArredondamento')"
                                        >
                                          <small>+ Adicionar</small>
                                        </button>
                                          <div class="col-12 table-responsive mt-2">
                                            <table class="table table-sm">
                                              <tr>
                                                <th>Faixa inicial</th>
                                                <th>Faixa final</th>
                                                <th>Faixa nota</th>
                                                <th>Ações</th>
                                              </tr>
                                              <tr v-for="(e, index) in fastRecuperacaoConfiguracaoArredondamentos" :key="index">
                                                <td>{{adicionaCasaDecimal(e.faixa_inicial)}}</td>
                                                <td>{{adicionaCasaDecimal(e.faixa_final)}}</td>
                                                <td>{{adicionaCasaDecimal(e.nota)}}</td>
                                                <td>
                                                  <button
                                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                                    @click="exibeModalEditarAvaliacaoArredondamento('R', e)"
                                                  >
                                                    <small>Editar</small>
                                                  </button>                                                
                                                </td>
                                              </tr>
                                            </table>
                                          </div>
                                      </div>
                                    </div>
                                    <div class="row mt-4">
                                      <div class="col-sm-12 col-md-12 col-lg-6 mb-2">
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <label class="h6 mt-4">Título da recuperação</label>
                                          </div>
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <input
                                              v-model="fastSecretariaRecuperacao.titulo_avaliacao"
                                              type="text"
                                              class="form-control"
                                            >
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-12 mb-2">
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <label class="h6 mt-4">Detalhes da recuperação</label>
                                          </div>
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <textarea
                                              v-model="fastSecretariaRecuperacao.detalhe_avaliacao"
                                              class="form-control"
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <label class="h6 mt-4">Tentativas máxima</label>
                                          </div>
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <input
                                              v-model="fastSecretariaRecuperacao.tentativas_maxima"
                                              type="number"
                                              class="form-control text-center"
                                            >
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-sm-12 col-md-12 col-lg-3 mb-2">
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <label class="h6 mt-4">Tempo limite</label>
                                          </div>
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <input
                                              v-model="fastSecretariaRecuperacao.tempo_limite"
                                              type="time"
                                              class="form-control text-center"
                                            >
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                                            <label class="h6 mt-4">Randomizar ordem</label>
                                            <div class="fd-app-curso-aulas mt-0">
                                            <div class="fd-app-curso-aulas-list mt-0">
                                              <div class="fd-app-curso-aulas-list-item shadow-none border-0 row p-0">
                                                <div class="col-sm-12 col-md-12 col-lg-12 mb-3">
                                                  <div class="col-12 text-center">
                                                    <label class="switch">
                                                      <input
                                                        v-model="fastSecretariaRecuperacao.randomizar_ordem"
                                                        type="checkbox"
                                                      >
                                                      <span class="slider round" />
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div> 
                                          </div>
                                        </div>
                                      </div>

                                      <div class="col-sm-12 col-md-12 col-lg-3 mb-2 d-none">
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <label class="h6 mt-4">Limite de questões</label>
                                          </div>
                                          <div class="col-sm-12 col-md-12 col-lg-12">
                                            <input
                                              v-model="fastSecretariaRecuperacao.limite_questoes"
                                              type="number"
                                              class="form-control text-center"
                                            >
                                          </div>
                                        </div>
                                      </div>

                                      <div v-if="fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao" class="col-12 text-center mt-4">
                                        <button
                                          class="btn btn-primary mt-2 ml-2"
                                          @click.prevent="editaSecretariaAvaliacao('R')"
                                        >
                                          <small>
                                            
                                            <b-icon-check2-circle /> 
                                            &nbsp;Salvar alterações
                                          </small>
                                        </button>
                                      </div>
                                      <div v-else class="col-12 text-center">
                                        <button
                                          class="btn btn-primary mt-2 ml-2"
                                          @click.prevent="insereSecretariaAvaliacao('R')"
                                        >
                                          <small>
                                            <i
                                              class="fa fa-floppy-o"
                                              aria-hidden="true"
                                            />
                                            &nbsp;Criar recuperação
                                          </small>
                                        </button>
                                      </div>

                                      <div
                                        v-if="fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao"
                                        class="col-12 mt-4"
                                      >
                                        <div class="row">
                                          <div class="col-sm-12 col-md-12 col-lg-6 mt-4">
                                            <h4 class="aluno_font_color">
                                              Questões da Avaliação ({{fastSecretariaRecuperacaoQuestoes.length}})
                                            </h4>
                                          </div>
                                          <div class="col-sm-12 col-md-12 col-lg-6 mt-4 text-right">
                                            <a
                                              class="btn btn-primary btn-sm mt-2 ml-2"
                                              :href="'/plataforma/' + $route.params.id_plataforma + '/admin/provas?id_secretaria_avaliacao=' + fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao + '&id_secretaria_avaliacao_uc=' + this.$route.params.id_secretaria_avaliacao_uc + '&id_avaliacao_modelo=' + this.$route.params.id_avaliacao_modelo + '#questões'"
                                            >
                                              <small>
                                                <i
                                                  class="fa fa-question-circle"
                                                  aria-hidden="true"
                                                />
                                                &nbsp;Gerenciar questões
                                              </small>
                                            </a>
                                          </div>
                                        </div>
                                        
                                        
                                        <div class="row pl-4 pr-4 pb-4 mt-2">
                                          <div class="col-12 mt-2 pl-4 pr-4">
                                            <div
                                              class="progress"
                                              style="height: 50px"
                                            >
                                              <div
                                                :class="'progress-bar progress-bar-striped progress-bar-animated bg-success'"
                                                role="progressbar"
                                                :aria-valuenow="{ width: fastSecretariaRecuperacaoPorcentagem }"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                :style="{ width: fastSecretariaRecuperacaoPorcentagem + '%' }"
                                              >
                                                <h6 class="m-0">
                                                  Recuperacao 
                                                </h6>
                                                <h6 class="m-0">
                                                  Peso:
                                                  <span>{{ fastSecretariaRecuperacaoQuestoesPeso }}</span>
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                          
                                          <div
                                            v-if="fastSecretariaRecuperacaoQuestoes.length"
                                            class="col-12"
                                          >
                                            <div class="row pl-4 pr-4 pb-4">
                                              <div class="col-12">
                                                <div class="row pb-4">
                                                  <carousel
                                                    :navigation-enabled="true"
                                                    :per-page="1"
                                                    :mouse-drag="false"
                                                    :adjustable-height="true"
                                                    :pagination-size="20"
                                                    :resistance-coef="40"
                                                    class="col-12 shadow-lg p-2 mb-2 bg-white rounded"
                                                  >
                                                    <slide
                                                      v-for="(q, index) in fastSecretariaRecuperacaoQuestoes"
                                                      :key="q.id_questao"
                                                    >
                                                      <div class="row p-4">
                                                        <div class="col-sm-12 col-md-12 col-lg-4 mt-2">
                                                          <h5>
                                                            Questão #{{q.id_questao}} - {{ index + 1 }} de
                                                            {{ fastSecretariaRecuperacaoQuestoes.length }}
                                                          </h5>
                                                        </div>
                                                        <div class="col-sm-12 col-md-12 col-lg-5 mt-2">
                                                          <div class="row">
                                                            <div class="col-sm-12 col-md-12 col-lg-7 text-right">
                                                              <h5>Peso da questão</h5>
                                                            </div>
                                                            <div class="col-sm-12 col-md-12 col-lg-5 pl-0">
                                                              <input
                                                                v-model="q.questao_peso"
                                                                type="number"
                                                                step=".01"
                                                                class="form-control text-center"
                                                                @change="atualizaPesoQuestaoAvaliacao(q, 'R')"
                                                              >
                                                            </div>
                                                          </div>
                                                        </div>
                                                        <div
                                                          class="col-sm-12 col-md-12 col-lg-3 mt-2 text-right"
                                                        >
                                                          <button
                                                            class="btn btn-sm btn-danger"
                                                            @click="exibeModalExcluirQuestaoAvaliacao(q, 'R')"
                                                          >
                                                            <small class="pt-0 pb-0">Excluir da avaliação</small>
                                                          </button>
                                                        </div>

                                                        <div class="col-12 mt-4">
                                                          <div v-html="q.pergunta" />
                                                        </div>
                                                        <div
                                                          v-if="q.alternativas.length"
                                                          class="col-12 p-4"
                                                        >
                                                          <div
                                                            v-for="(a) in q.alternativas"
                                                            :key="a.id_alternativa"
                                                            class="row pl-2 pr-2"
                                                          >
                                                            <div :class="'col-12 alt-correta-' + a.correta">
                                                              <div class="row">
                                                                <div class="col-6 mt-2">
                                                                  <h6>Alternativa #{{ a.id_alternativa }}</h6>
                                                                </div>
                                                                <div class="col-12">
                                                                  <div v-html="a.alternativa" />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </slide>
                                                  </carousel>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>  
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>                        
                  </div>
                </div>
              </div>

              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">              
                <a
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/avaliacao-modelo/' + parseInt($route.params.id_avaliacao_modelo))"
                >
                  <b-icon-arrow-return-left />
                  Voltar 
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalCriarAvaliacaoArredondamento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo modelo</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarAvaliacaoArredondamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa inicial</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastAvaliacaoArredondamentoNovo.faixa_inicial" />
            </div>   
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa final</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastAvaliacaoArredondamentoNovo.faixa_final" />
            </div> 
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nota</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastAvaliacaoArredondamentoNovo.nota" />
            </div>                  
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="insereAvaliacaoArredondamento('A')"
                >
                  Adicionar arredondamento
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarAvaliacaoArredondamento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarAvaliacaoArredondamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa inicial</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastAvaliacaoArredondamentoEditar.faixa_inicial" />
            </div>   
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa final</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastAvaliacaoArredondamentoEditar.faixa_final" />
            </div> 
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nota</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" readonly v-model="fastAvaliacaoArredondamentoEditar.nota" />
            </div>                  
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editaAvaliacaoArredondamento('A')"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
            <div class="col-12 mt-4 text-right">
              <button class="btn btn-sm btn-danger" @click="excluiAvaliacaoArredondamento('A')">
                <small><b-icon-trash  /> Excluir</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalCriarRecuperacaoArredondamento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Novo modelo</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalCriarRecuperacaoArredondamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa inicial</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastRecuperacaoArredondamentoNovo.faixa_inicial" />
            </div>   
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa final</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastRecuperacaoArredondamentoNovo.faixa_final" />
            </div> 
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nota</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastRecuperacaoArredondamentoNovo.nota" />
            </div>                  
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="insereAvaliacaoArredondamento('R')"
                >
                  Adicionar arredondamento
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalEditarRecuperacaoArredondamento"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-8 pl-4 pr-4">
          <h4>Editar</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-4 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalEditarRecuperacaoArredondamento')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa inicial</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastRecuperacaoArredondamentoEditar.faixa_inicial" />
            </div>   
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Faixa final</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" v-model="fastRecuperacaoArredondamentoEditar.faixa_final" />
            </div> 
            <div class="col-sm-12 col-md-12 col-lg-4 mb-2">
              <label>Nota</label>              
              <input type="number" min="0.00" max="10.00" step="0.01" class="form-control" readonly v-model="fastRecuperacaoArredondamentoEditar.nota" />
            </div>                  
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  href="#"
                  type="button"
                  class="btn btn-primary"
                  @click="editaAvaliacaoArredondamento('R')"
                >
                  Salvar alterações
                </button>
              </div>
            </div>
            <div class="col-12 mt-4 text-right">
              <button class="btn btn-sm btn-danger" @click="excluiAvaliacaoArredondamento('R')">
                <small><b-icon-trash  /> Excluir</small>
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import { Carousel, Slide } from "vue-carousel"; //https://github.com/ssense/vue-carousel#readme

export default {
  name: "HomeInternoSecretariaAvaliacaoModeloUc",
  components: {
    Carousel,
    Slide,
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Modelo UCs
      fastModeloUcs: {
        total: [],
        filtro: [],
        paginacao: [],
        uc: {
          avaliacao_configurada: "",
          creditos: "",
          detalhe_curso: "",
          id_avaliacao_modelo: "",
          id_curso_uc: "",
          id_curso_uc_recuperacao: "",
          id_pai: "",
          id_secretaria_avaliacao_principal: "",
          id_secretaria_avaliacao_recuperacao: "",
          id_secretaria_avaliacao_uc: "",
          id_secretaria_curso_programa_unidade: 0,
          id_secretaria_curso_proximo_programa: "",
          id_secretaria_curso_proximo_uc: "",
          nome_curso: "",
          preco: "",
          recuperacao_configurada: "",
          sequencia: "",
          tipo_avaliacao: "",
        },
        configuracoes: [],
        loading: true
      },
      fastAvaliacaoConfiguracao: {
        id_avaliacao_configuracao: 0,
        tipo: "A",
        final_modulo: true,
        liberar_prazo: 30,
        nota_maxima: "",
        passar_direto: "",
        media_aprovacao: "",
        media_recuperacao: "",
        id_resultado_passar_direto: 0,
        id_resultado_media_aprovacao: 0,
        id_resultado_media_recuperacao: 0,
        id_secretaria_avaliacao_uc: 0      
      }, 
      fastRecuperacaoConfiguracao: {
        id_avaliacao_configuracao: 0,
        tipo: "R",
        final_modulo: true,
        liberar_prazo: 30,
        nota_maxima: "",
        passar_direto: "",
        media_aprovacao: "",
        media_recuperacao: "",
        id_resultado_passar_direto: 0,
        id_resultado_media_aprovacao: 0,
        id_resultado_media_recuperacao: 0,
        id_secretaria_avaliacao_uc: 0                
      }, 
      fastAvaliacaoConfiguracaoArredondamentos: [],
      fastRecuperacaoConfiguracaoArredondamentos: [],
      fastAvaliacaoArredondamentoNovo: {
        id_avaliacao_configuracao: 0,
        faixa_inicial: 0.00,
        faixa_final: 0.00,
        nota: 0.00
      },
      fastRecuperacaoArredondamentoNovo: {
        id_avaliacao_configuracao: 0,
        faixa_inicial: 0.00,
        faixa_final: 0.00,
        nota: 0.00
      },
      fastAvaliacaoArredondamentoEditar: {
        id_avaliacao_configuracao: 0,
        faixa_inicial: 0.00,
        faixa_final: 0.00,
        nota: 0.00
      },
      fastRecuperacaoArredondamentoEditar: {
        id_avaliacao_configuracao: 0,
        faixa_inicial: 0.00,
        faixa_final: 0.00,
        nota: 0.00
      },
      fastSecretariaAvaliacao: {
        id_secretaria_avaliacao: 0,
        titulo_avaliacao: "",
        detalhe_avaliacao: "",
        tentativas_maxima: 1,
        tempo_limite: '00:00',
        randomizar_ordem: false,
        limite_questoes: 0,
        id_plataforma: this.$route.params.id_plataforma
      },
      fastSecretariaRecuperacao: {
        id_secretaria_avaliacao: 0,
        titulo_avaliacao: "",
        detalhe_avaliacao: "",
        tentativas_maxima: 1,
        tempo_limite: '00:00',
        randomizar_ordem: false,
        limite_questoes: 0,
        id_plataforma: this.$route.params.id_plataforma
      },
      fastSecretariaAvaliacaoQuestoes: [],
      fastSecretariaRecuperacaoQuestoes: [],
      fastSecretariaAvaliacaoPorcentagem: 0,
      fastSecretariaRecuperacaoPorcentagem: 0,
      fastSecretariaAvaliacaoQuestoesPeso: 0,
      fastSecretariaRecuperacaoQuestoesPeso: 0,
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_avaliacao_modelo && this.$route.params.id_secretaria_avaliacao_uc) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Recupera modelo ucs
            this.getModeloUcs(this.$route.params.id_plataforma, this.$route.params.id_avaliacao_modelo)
            
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
            
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    async getModeloUcs(id_plataforma, id_avaliacao_modelo){
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_uc/lista", "id_plataforma=" + id_plataforma + "&id_avaliacao_modelo=" + id_avaliacao_modelo)
      .then(obj => {        
        if (obj.length) {
          obj.forEach(uc => {
            if (this.$route.params.id_secretaria_avaliacao_uc == uc.id_secretaria_avaliacao_uc) {
              this.fastModeloUcs.uc = uc
              if (this.fastModeloUcs.uc.id_secretaria_avaliacao_principal) this.getSecretariaAvaliacao(this.$route.params.id_plataforma, this.fastModeloUcs.uc.id_secretaria_avaliacao_principal, 'A')
              if (this.fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao) this.getSecretariaAvaliacao(this.$route.params.id_plataforma, this.fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao, 'R')
            }
          })
          this.fastModeloUcs.total = obj
          this.fastModeloUcs.filtro = obj
        }
        else {
          this.fastModeloUcs.total = []
          this.fastModeloUcs.filtro = []
        }            
        this.$store.state.fastCarregando = false;
        this.fastModeloUcs.loading = false

        this.getAvaliacaoConfiguracoes(this.$route.params.id_secretaria_avaliacao_uc)
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar UCs do modelo de avaliação", "error");
        this.$store.state.fastCarregando = false;
        this.fastModeloUcs.loading = false
      })
    },
    async getAvaliacaoConfiguracoes(id_secretaria_avaliacao_uc){
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_configuracao/lista", "id_secretaria_avaliacao_uc=" + id_secretaria_avaliacao_uc)
      .then(obj => {        
        console.log("api/fast_secretaria_avaliacao_configuracao/lista", obj)
        if (obj.length) {
          this.fastModeloUcs.configuracoes = obj
          obj.forEach(c => {
            if (c.tipo == "A") {
              this.fastAvaliacaoConfiguracao = c
              if (this.$refs.selPeriodoAvaliacao) {
                if (this.fastAvaliacaoConfiguracao.final_modulo) this.$refs.selPeriodoAvaliacao.value = "F"
                else
                  this.$refs.selPeriodoAvaliacao.value = "D"
              }
              
            } else if (c.tipo == "R") {
              this.fastRecuperacaoConfiguracao = c
              if (this.$refs.selPeriodoRecuperacao) {
                if (this.fastRecuperacaoConfiguracao.final_modulo) this.$refs.selPeriodoRecuperacao.value = "F"
                else
                  this.$refs.selPeriodoRecuperacao.value = "D"
              }              
            }

            this.getAvaliacaoArredondamento(c)
            
            if (c.tipo == "A") this.fastAvaliacaoArredondamentoNovo.id_avaliacao_configuracao = c.id_avaliacao_configuracao 
            else
              this.fastRecuperacaoArredondamentoNovo.id_avaliacao_configuracao = c.id_avaliacao_configuracao 
          })
        }
        else {
          this.fastModeloUcs.configuracoes = []
        }            
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar configurações da avaliação", "error")
        console.log(e)
      })
    },
    async getAvaliacaoArredondamento(obj){
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_arredondamento/lista", "id_avaliacao_configuracao=" + obj.id_avaliacao_configuracao)
      .then(res => {        
        
        if (res.length) {
          if (obj.tipo == "A") {
            this.fastAvaliacaoConfiguracaoArredondamentos = res
          } else {
            this.fastRecuperacaoConfiguracaoArredondamentos = res  
          }          
        }
        else {
          if (obj.tipo == "A") {
            this.fastAvaliacaoConfiguracaoArredondamentos = []  
          } else {
            this.fastRecuperacaoConfiguracaoArredondamentos = []   
          }  
        }       
          
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar arredondamentos", "error")
        console.log(e)
      })
    },
    editaAvaliacaoUc(){
      console.log("this.fastModeloUcs.uc", this.fastModeloUcs.uc)
      let erros = []
      this.fastModeloUcs.uc.id_curso_uc = 0
      this.fastModeloUcs.uc.id_secretaria_curso_programa_unidade = 0
      this.fastModeloUcs.uc.id_avaliacao_modelo = 0
      if (!this.fastModeloUcs.uc.tipo_avaliacao) erros.push("Campo obrigatório: tipo_avaliacao")
      if (!this.fastModeloUcs.uc.id_curso_uc_recuperacao) this.fastModeloUcs.uc.id_curso_uc_recuperacao = 0
      if (!this.fastModeloUcs.uc.id_secretaria_curso_proximo_uc) this.fastModeloUcs.uc.id_secretaria_curso_proximo_uc = 0
      if (!this.fastModeloUcs.uc.id_secretaria_curso_proximo_programa) this.fastModeloUcs.uc.id_secretaria_curso_proximo_programa = 0
      if (!this.fastModeloUcs.uc.id_secretaria_avaliacao_principal) this.fastModeloUcs.uc.id_secretaria_avaliacao_principal = 0
      if (!this.fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao) this.fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao = 0
      
      if (!this.fastModeloUcs.uc.avaliacao_configurada) this.fastModeloUcs.uc.avaliacao_configurada = false
      else
        this.fastModeloUcs.uc.avaliacao_configurada = true

      if (!this.fastModeloUcs.uc.recuperacao_configurada) this.fastModeloUcs.uc.recuperacao_configurada = false
      else
        this.fastModeloUcs.uc.recuperacao_configurada = true

        if (erros.length) {
            erros.forEach(e => this.exibeToasty(e, "error"))
        } else {
          this.$store.state.fastCarregando = true;
            this.promisePostFastApi(this.fastModeloUcs.uc, "api/fast_secretaria_avaliacao_uc/atualiza")
            .then((res) => {
                this.exibeToasty("Dados atualizados com sucesso", "success");
                this.$store.state.fastCarregando = false;
            }).catch((e) => {
                this.exibeToasty("Erro ao atualizar dados", "error");
                this.$store.state.fastCarregando = false;
            });
        }     
    },
    alteraPeriodoAvaliacao(event) {
      switch (event.srcElement.value) {
        case 'D':
          this.fastAvaliacaoConfiguracao.final_modulo = false    
          this.fastAvaliacaoConfiguracao.liberar_prazo = 30  
          this.editaAvaliacaoConfiguracao()    
          break;
        case 'F':
          this.fastAvaliacaoConfiguracao.final_modulo = true
          this.fastAvaliacaoConfiguracao.liberar_prazo = 0
          this.editaAvaliacaoConfiguracao()    
          break;
        default:
          break;
      }
    },
    alteraPeriodoRecuperacao(event) {
      switch (event.srcElement.value) {
        case 'D':
          this.fastRecuperacaoConfiguracao.final_modulo = false    
          this.fastRecuperacaoConfiguracao.liberar_prazo = 30  
          this.editaRecuperacaoConfiguracao()    
          break;
        case 'F':
          this.fastRecuperacaoConfiguracao.final_modulo = true
          this.fastRecuperacaoConfiguracao.liberar_prazo = 0
          this.editaRecuperacaoConfiguracao()    
          break;
        default:
          break;
      }
    },
    editaAvaliacaoConfiguracao(){
      let acao = "insere"
      if (this.fastAvaliacaoConfiguracao.id_avaliacao_configuracao) acao = 'atualiza'
      this.fastAvaliacaoConfiguracao.id_secretaria_avaliacao_uc = this.$route.params.id_secretaria_avaliacao_uc
      if (!this.fastAvaliacaoConfiguracao.id_resultado_passar_direto) this.fastAvaliacaoConfiguracao.id_resultado_passar_direto = 0
      if (!this.fastAvaliacaoConfiguracao.id_resultado_media_aprovacao) this.fastAvaliacaoConfiguracao.id_resultado_media_aprovacao = 0
      if (!this.fastAvaliacaoConfiguracao.id_resultado_media_recuperacao) this.fastAvaliacaoConfiguracao.id_resultado_media_recuperacao = 0
       if (!this.fastAvaliacaoConfiguracao.nota_maxima) this.fastAvaliacaoConfiguracao.nota_maxima = 0.00
       if (!this.fastAvaliacaoConfiguracao.passar_direto) this.fastAvaliacaoConfiguracao.passar_direto = 0.00
       if (!this.fastAvaliacaoConfiguracao.media_aprovacao) this.fastAvaliacaoConfiguracao.media_aprovacao = 0.00
       if (!this.fastAvaliacaoConfiguracao.media_recuperacao) this.fastAvaliacaoConfiguracao.media_recuperacao = 0.00
       if (!this.fastAvaliacaoConfiguracao.liberar_prazo) this.fastAvaliacaoConfiguracao.liberar_prazo = 0
      this.$store.state.fastCarregando = true

      console.log("this.fastAvaliacaoConfiguracao", this.fastAvaliacaoConfiguracao)
      
      this.promisePostFastApi(this.fastAvaliacaoConfiguracao, "api/fast_secretaria_avaliacao_configuracao/" + acao)
      .then((res) => {
          this.exibeToasty("Dados atualizados com sucesso", "success");
          // Recupera modelo ucs
          this.getModeloUcs(this.$route.params.id_plataforma, this.$route.params.id_avaliacao_modelo)
          this.$store.state.fastCarregando = false;
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar dados", "error");
          this.$store.state.fastCarregando = false;
      });
    },
    editaRecuperacaoConfiguracao(){
      let acao = "insere"
      if (this.fastRecuperacaoConfiguracao.id_avaliacao_configuracao) acao = 'atualiza'
      this.fastRecuperacaoConfiguracao.id_secretaria_avaliacao_uc = this.$route.params.id_secretaria_avaliacao_uc
      if (!this.fastRecuperacaoConfiguracao.id_resultado_passar_direto) this.fastRecuperacaoConfiguracao.id_resultado_passar_direto = 0
      if (!this.fastRecuperacaoConfiguracao.id_resultado_media_aprovacao) this.fastRecuperacaoConfiguracao.id_resultado_media_aprovacao = 0
      if (!this.fastRecuperacaoConfiguracao.id_resultado_media_recuperacao) this.fastRecuperacaoConfiguracao.id_resultado_media_recuperacao = 0
       if (!this.fastRecuperacaoConfiguracao.nota_maxima) this.fastRecuperacaoConfiguracao.nota_maxima = 0.00
       if (!this.fastRecuperacaoConfiguracao.passar_direto) this.fastRecuperacaoConfiguracao.passar_direto = 0.00
       if (!this.fastRecuperacaoConfiguracao.media_aprovacao) this.fastRecuperacaoConfiguracao.media_aprovacao = 0.00
       if (!this.fastRecuperacaoConfiguracao.media_recuperacao) this.fastRecuperacaoConfiguracao.media_recuperacao = 0.00
       if (!this.fastRecuperacaoConfiguracao.liberar_prazo) this.fastRecuperacaoConfiguracao.liberar_prazo = 0
      this.$store.state.fastCarregando = true

      console.log("this.fastRecuperacaoConfiguracao", this.fastRecuperacaoConfiguracao)
      
      this.promisePostFastApi(this.fastRecuperacaoConfiguracao, "api/fast_secretaria_avaliacao_configuracao/" + acao)
      .then((res) => {
          this.exibeToasty("Dados atualizados com sucesso", "success");
          this.$store.state.fastCarregando = false;
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar dados", "error");
          this.$store.state.fastCarregando = false;
      });
    },
    insereAvaliacaoArredondamento(tipo){
      let obj = {}
      if (tipo == "A") {
        obj = this.fastAvaliacaoArredondamentoNovo
      } else {
        obj = this.fastRecuperacaoArredondamentoNovo
      }
      if (!obj.faixa_inicial) obj.faixa_inicial = 0.00
      if (!obj.faixa_final) obj.faixa_final = 0.00
      if (!obj.nota) obj.nota = 0.00

      this.$store.state.fastCarregando = true
      this.promisePostFastApi(obj, "api/fast_secretaria_avaliacao_arredondamento/insere")
      .then((res) => {
          this.exibeToasty("Dados atualizados com sucesso", "success");
          this.$store.state.fastCarregando = false;
          
                   
          if (tipo == "A") {
            this.fastAvaliacaoConfiguracaoArredondamentos = res    
            this.hideModal('modalCriarAvaliacaoArredondamento')
          } else {
            this.fastRecuperacaoConfiguracaoArredondamentos = res  
            this.hideModal('modalCriarRecuperacaoArredondamento')   
          }    
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar dados", "error");
          this.$store.state.fastCarregando = false;
      });

    },
    exibeModalEditarAvaliacaoArredondamento(tipo, obj){
      if (tipo == "A") {
        this.fastAvaliacaoArredondamentoEditar = obj
        this.showModal('modalEditarAvaliacaoArredondamento')
      } else {
        this.fastRecuperacaoArredondamentoEditar = obj
        this.showModal('modalEditarRecuperacaoArredondamento')
      }
    },
    editaAvaliacaoArredondamento(tipo){
      let obj = {}
      if (tipo == "A") {
        obj = this.fastAvaliacaoArredondamentoEditar
      } else {
        obj = this.fastRecuperacaoArredondamentoEditar
      }
      if (!obj.faixa_inicial) obj.faixa_inicial = 0.00
      if (!obj.faixa_final) obj.faixa_final = 0.00
      if (!obj.nota) obj.nota = 0.00

      this.$store.state.fastCarregando = true
      this.promisePostFastApi(obj, "api/fast_secretaria_avaliacao_arredondamento/atualiza")
      .then((res) => {
          this.exibeToasty("Dados atualizados com sucesso", "success");
          if (tipo == "A") {
            this.hideModal('modalEditarAvaliacaoArredondamento')
          } else {
            this.hideModal('modalEditarRecuperacaoArredondamento')
          }
          
          this.$store.state.fastCarregando = false;
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar dados", "error");
          this.$store.state.fastCarregando = false;
      });

    },
    excluiAvaliacaoArredondamento(tipo){
      let obj = {}
      if (tipo == "A") {
        obj = this.fastAvaliacaoArredondamentoEditar
      } else {
        obj = this.fastRecuperacaoArredondamentoEditar
      }
      if (!obj.faixa_inicial) obj.faixa_inicial = 0.00
      if (!obj.faixa_final) obj.faixa_final = 0.00
      if (!obj.nota) obj.nota = 0.00

      this.$store.state.fastCarregando = true
      this.promisePostFastApi(obj, "api/fast_secretaria_avaliacao_arredondamento/exclui")
      .then((res) => {
          this.exibeToasty("Exclusão efetuada com sucesso", "success");
          if (tipo == "A") {
            this.fastAvaliacaoConfiguracaoArredondamentos = res  
            this.hideModal('modalEditarAvaliacaoArredondamento')
          } else {
            this.fastRecuperacaoConfiguracaoArredondamentos = res 
            this.hideModal('modalEditarRecuperacaoArredondamento')
          }
          
          this.$store.state.fastCarregando = false;
      }).catch((e) => {
          this.exibeToasty("Erro ao excluir", "error");
          this.$store.state.fastCarregando = false;
      });

    },
    adicionaCasaDecimal(value){      
      let resultado = 0.00
      if (value) {
        resultado = parseFloat(value)
      }
      return resultado.toFixed(2)
    },
    getSecretariaAvaliacao(id_plataforma, id_secretaria_avaliacao, tipo){
      this.promiseGetFastApi("api/fast_secretaria_avaliacao/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_avaliacao=" + id_secretaria_avaliacao)
      .then(obj => {   
        console.log("api/fast_secretaria_avaliacao/lista", tipo, obj)        
        if (obj.length) {
          if (tipo == 'A') this.fastSecretariaAvaliacao = obj[0]
          else
          this.fastSecretariaRecuperacao = obj[0]

          this.getSecretariaQuestoes(id_plataforma, id_secretaria_avaliacao, tipo)
        }
      }).catch(e => {
        console.log(e);
      })
    },
    getSecretariaQuestoes(id_plataforma, id_secretaria_avaliacao, tipo){
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_questoes/lista", "id_plataforma=" + id_plataforma + "&id_secretaria_avaliacao=" + id_secretaria_avaliacao)
      .then(obj => {   
        console.log("api/fast_secretaria_avaliacao_questoes/lista", tipo, obj)        
        if (obj.length) {
          const questoesUnicas = Array.from(new Set(obj.map((a) => a.id_questao))).map((id_questao) => {
            return obj.find((a) => a.id_questao === id_questao);
          });

          let peso_questoes = 0
          questoesUnicas.forEach((a, index) => {
            peso_questoes += a.questao_peso
            obj.forEach((q, index2) => {
              if (a.id_questao == q.id_questao) {
                if (!questoesUnicas[index].alternativas) questoesUnicas[index].alternativas = [];
                questoesUnicas[index].alternativas.push(q);
              }
            });
          });

          console.log("peso_questoes", peso_questoes)

          if (tipo == 'A') {
            this.fastSecretariaAvaliacaoQuestoes = questoesUnicas
            this.fastSecretariaAvaliacaoPorcentagem = (peso_questoes * 100) / this.fastAvaliacaoConfiguracao.nota_maxima
            this.fastSecretariaAvaliacaoQuestoesPeso = peso_questoes

            console.log("this.fastSecretariaAvaliacaoPorcentagem", this.fastSecretariaAvaliacaoPorcentagem)
            console.log("this.fastSecretariaAvaliacaoQuestoesPeso", this.fastSecretariaAvaliacaoQuestoesPeso)
          }
          else{
            this.fastSecretariaRecuperacaoQuestoes = questoesUnicas
            this.fastSecretariaRecuperacaoPorcentagem = (peso_questoes * 100) / this.fastRecuperacaoConfiguracao.nota_maxima
            this.fastSecretariaRecuperacaoQuestoesPeso = peso_questoes

            console.log("this.fastSecretariaRecuperacaoPorcentagem", this.fastSecretariaRecuperacaoPorcentagem)
            console.log("this.fastSecretariaRecuperacaoQuestoesPeso", this.fastSecretariaRecuperacaoQuestoesPeso)
          }
          
        }
      }).catch(e => {
        console.log(e);
      })
    },
    insereSecretariaAvaliacao(tipo){
      let erros = []

      let obj = {}
      if (tipo == "A") {
        obj = this.fastSecretariaAvaliacao
      } else {
        obj = this.fastSecretariaRecuperacao
      }

      if (!obj.titulo_avaliacao) erros.push("O título da avaliação é obrigatório")
      if (erros.length){
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
          if (!obj.tempo_limite) obj.tempo_limite = '00:00'
          if (!obj.tentativas_maxima) obj.tentativas_maxima = 1
          if (!obj.randomizar_ordem) obj.randomizar_ordem = false
          if (!obj.limite_questoes) obj.limite_questoes = 0

          console.log("api/fast_secretaria_avaliacao/insere", obj)

          this.$store.state.fastCarregando = true
          this.promisePostFastApi(obj, "api/fast_secretaria_avaliacao/insere")
          .then((res) => {
            if (res.length) {
              this.exibeToasty("Dados atualizados com sucesso", "success");
              this.$store.state.fastCarregando = false;                   
              if (tipo == "A") {
                this.fastModeloUcs.uc.id_secretaria_avaliacao_principal = res[0].id_secretaria_avaliacao 
                this.fastSecretariaAvaliacao.id_secretaria_avaliacao  = res[0].id_secretaria_avaliacao 
                this.editaAvaliacaoUc()
              } else {
                this.fastModeloUcs.uc.id_secretaria_avaliacao_recuperacao = res[0].id_secretaria_avaliacao
                this.fastSecretariaRecuperacao.id_secretaria_avaliacao  = res[0].id_secretaria_avaliacao 
                this.editaAvaliacaoUc()
              }   
            } else {
              this.exibeToasty("Erro ao atualizar dados", "error");
              this.$store.state.fastCarregando = false;
            }
              
          }).catch((e) => {
              this.exibeToasty("Erro ao atualizar dados", "error");
              this.$store.state.fastCarregando = false;
          });
      }
     

    },
    editaSecretariaAvaliacao(tipo){
      let erros = []

      let obj = {}
      if (tipo == "A") {
        obj = this.fastSecretariaAvaliacao
      } else {
        obj = this.fastSecretariaRecuperacao
      }

      if (!obj.titulo_avaliacao) erros.push("O título da avaliação é obrigatório")
      if (!obj.id_secretaria_avaliacao) erros.push("Identificador da avaliação não identificado")
      if (erros.length){
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
          if (!obj.tempo_limite) obj.tempo_limite = '00:00'
          if (!obj.tentativas_maxima) obj.tentativas_maxima = 1
          if (!obj.randomizar_ordem) obj.randomizar_ordem = false
          if (!obj.limite_questoes) obj.limite_questoes = 0

          console.log("api/fast_secretaria_avaliacao/atualiza", obj)

          this.$store.state.fastCarregando = true
          this.promisePostFastApi(obj, "api/fast_secretaria_avaliacao/atualiza")
          .then((res) => {
            if (res.length) {
              this.exibeToasty("Dados atualizados com sucesso", "success");
              this.$store.state.fastCarregando = false;                   
              
            } else {
              this.exibeToasty("Erro ao atualizar dados", "error");
              this.$store.state.fastCarregando = false;
            }
              
          }).catch((e) => {
              this.exibeToasty("Erro ao atualizar dados", "error");
              this.$store.state.fastCarregando = false;
          });
      }
     

    },
    async atualizaPesoQuestaoAvaliacao(questao, tipo) {
      let fast_secretaria_avaliacao_questoes = {
        id_secretaria_avaliacao: this.fastSecretariaAvaliacao.id_secretaria_avaliacao,
        id_questao: questao.id_questao,
        questao_peso: questao.questao_peso,
      };

      if (tipo == "R") {
        fast_secretaria_avaliacao_questoes.id_secretaria_avaliacao = this.fastSecretariaRecuperacao.id_secretaria_avaliacao
      }

      this.$store.state.fastCarregando = true
      this.promisePostFastApi(fast_secretaria_avaliacao_questoes, "api/fast_secretaria_avaliacao_questoes/atualiza")
      .then((res) => {
          this.exibeToasty("Dados atualizados com sucesso", "success");         
          this.getSecretariaQuestoes(this.$route.params.id_plataforma, fast_secretaria_avaliacao_questoes.id_secretaria_avaliacao, tipo)
          this.$store.state.fastCarregando = false;
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar dados", "error");
          this.$store.state.fastCarregando = false;
      });

    },
    exibeModalExcluirQuestaoAvaliacao(questao, tipo){
      let fast_secretaria_avaliacao_questoes = {
        id_secretaria_avaliacao: this.fastSecretariaAvaliacao.id_secretaria_avaliacao,
        id_questao: questao.id_questao,
        questao_peso: questao.questao_peso,
      };

      if (tipo == "R") {
        fast_secretaria_avaliacao_questoes.id_secretaria_avaliacao = this.fastSecretariaRecuperacao.id_secretaria_avaliacao
      }

      this.$store.state.fastCarregando = true
      this.promisePostFastApi(fast_secretaria_avaliacao_questoes, "api/fast_secretaria_avaliacao_questoes/exclui")
      .then((res) => {
          this.exibeToasty("Dados atualizados com sucesso", "success");         
          this.getSecretariaQuestoes(this.$route.params.id_plataforma, fast_secretaria_avaliacao_questoes.id_secretaria_avaliacao, tipo)
          this.$store.state.fastCarregando = false;
      }).catch((e) => {
          this.exibeToasty("Erro ao atualizar dados", "error");
          this.$store.state.fastCarregando = false;
      });
    }
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
